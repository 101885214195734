<!--
 * @Description:
 * @Author: heqi
 * @Date: 2022-07-13 15:39:33
 * @LastEditTime: 2022-11-11 14:24:42
 * @LastEditors: wangjuan
 * @FilePath: \sns_web\src\views\layout\work\okr\alignTheView\TargetAligned.vue
-->
<template>
  <section>
    <div class="tree-container"
    v-loading="loading"
    element-loading-text="Loading...">
      <el-scrollbar ref="scrollRef">
          <VueOkrTree
            :data="alignedDataList"
            direction="horizontal"
            node-key="index"
            show-collapsable
            :render-content="renderContent"
            @node-expand="handleNodeExpand"
            @node-collapse="handleNodeCollapse"
            @node-click="dialog"
            :node-btn-content="renderBtnContent"
            :default-expanded-keys="expandedKeys">
          </VueOkrTree>
            <!-- showNodeNum -->
      </el-scrollbar>

    </div>
  </section>
   <TargetOkrDialog ref="targetOkrDialogRef"></TargetOkrDialog>
</template>

<script>
import { onMounted, reactive, toRefs, computed, watch } from 'vue'
import { getAlignList } from '@/apis/okr.js'
import { VueOkrTree } from 'vue-okr-tree-vue3'
import 'vue-okr-tree-vue3/dist/vue-okr-tree.css'
import eventBus from '@/utils/eventBus.js'
import TargetOkrDialog from '@/views/layout/work/okr/alignTheView/component/TargetOkrDialog'
export default {
  name: 'TargetAligned',
  components: { VueOkrTree, TargetOkrDialog },
  props: {
    dateList: {
      type: Array,
      default: () => []
    }
  },
  setup (props) {
    const state = reactive({
      scrollRef: null,
      okrCalendar: null,
      alignView: 5, // 对齐视图ID
      currentNavId: 1,
      dateList: computed(() => props.dateList),
      alignedDataList: [],
      loading: false,
      alignmentStatus: true,
      targetOkrDialogRef: null,
      expandedKeys: []
    })

    onMounted(() => {
      getAlignLists()
    })

    // 视图展示
    const getAlignLists = async () => {
      let index = 0
      /**
       * @name 前端生成下标
       */
      const eachAlignedDataList = (item) => {
        item.index = index
        index++

        if (item.children) {
          // console.log(item.children)

          for (let index = 0; index < item.children.length; index++) {
            eachAlignedDataList(item.children[index])
          }
          // item.children.forEach((citem) => {
          //   eachAlignedDataList(citem)
          // })
        }
      }

      /**
       * @name 获取下级的总结点
       */
      const getNoteNumber = (item, length = 0) => {
        if (item.children.length === 0 || item.children.length === undefined) {
          item.childrenLength = 0
          return 0
        } else {
          if (item.children) {
            let leafCount = length
            for (let index = 0; index < item.children.length; index++) {
              const children = item.children[index]
              leafCount += getNoteNumber(children, children.children.length)
              item.childrenLength = leafCount
            }
            return leafCount
          }
        }
      }

      state.loading = true
      const params = { align_type: 1, cycle_id: props.dateList, type: 1 }
      const { code, data } = await getAlignList(params)
      if (code === 0) {
        state.expandedKeys = []
        state.alignedDataList = data

        // 设置默认展开
        state.alignedDataList.forEach((item) => {
          eachAlignedDataList(item)
          getNoteNumber(item)
          if (item.children.length > 0) {
            item.childrenLength += item.children.length
            state.expandedKeys.push(item.index)
          }
        })
      }

      state.loading = false
    }

    watch(() => state.dateList, (value) => {
      getAlignLists()
    })

    // 开启弹出
    const dialog = async (item) => {
      state.loading = true
      state.loading = await state.targetOkrDialogRef.dialog(item)
    }

    const changeView = (id) => {
      state.currentNavId = id
    }

    // 展开
    const handleNodeExpand = (data) => {
      // console.log('展开', data)
    }

    // 收起
    const handleNodeCollapse = (data) => {
      // console.log('收起', data)
    }

    // 渲染内容
    const renderContent = (h, node) => {
      return (
        <div>
          <section class={['three-card', node.isCurrent ? 'current-select' : '']} >
            <div class="three-card-head">
              <p class="tag">{node.data.ork_type}</p>
              <el-progress type="circle" percentage={node.data.schedule} show-text width={16} stroke-width={3} stroke-linecap="butt" color="#7972F0"/>
            </div>
            <div class="three-card-content">
              <h3>{node.data.objective_name}</h3>
              <p class="tag">
                <span class="dept">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="16" height="16" rx="8" fill="#7972F0"/>
                    <path d="M6.0013 3.33333C6.0013 2.59695 6.59826 2 7.33464 2H8.66797C9.40435 2 10.0013 2.59695 10.0013 3.33333V4.66667C10.0013 5.40305 9.40435 6 8.66797 6V7.33333H10.668C11.7725 7.33333 12.668 8.22876 12.668 9.33333V10C13.0362 10 13.3346 10.2985 13.3346 10.6667V12C13.3346 12.3682 13.0362 12.6667 12.668 12.6667H11.3346C10.9664 12.6667 10.668 12.3682 10.668 12V10.6667C10.668 10.2985 10.9664 10 11.3346 10V9.33333C11.3346 8.96514 11.0362 8.66667 10.668 8.66667H8.66797V10C9.03616 10 9.33464 10.2985 9.33464 10.6667V12C9.33464 12.3682 9.03616 12.6667 8.66797 12.6667H7.33464C6.96645 12.6667 6.66797 12.3682 6.66797 12V10.6667C6.66797 10.2985 6.96645 10 7.33464 10V8.66667H5.33464C4.96645 8.66667 4.66797 8.96514 4.66797 9.33333V10C5.03616 10 5.33464 10.2985 5.33464 10.6667V12C5.33464 12.3682 5.03616 12.6667 4.66797 12.6667H3.33464C2.96645 12.6667 2.66797 12.3682 2.66797 12V10.6667C2.66797 10.2985 2.96645 10 3.33464 10V9.33333C3.33464 8.22876 4.23007 7.33333 5.33464 7.33333H7.33464V6C6.59826 6 6.0013 5.40305 6.0013 4.66667V3.33333Z" fill="white"/>
                  </svg>
                  <span>{node.data.dept_name}</span>
                </span>

                <span class="cycle">{node.data.cycle}</span>
              </p>
            </div>
          </section>
        </div>
      )
    }

    // 渲染子节点
    const renderBtnContent = (h, node) => {
      if (!node.expanded) {
        return (
          <div class="num">{node.data.childrenLength}</div>
        )
      }
    }

    // 回到顶部
    eventBus.$on('setScrollTop', () => {
      state.scrollRef.setScrollTop(0)
    })

    return {
      ...toRefs(state),
      dialog,
      changeView,
      renderContent,
      handleNodeExpand,
      handleNodeCollapse,
      renderBtnContent
      // setScrollTop
    }
  }
}
</script>

<style lang="less" scoped>
.tree-container {
  position: fixed;
  padding-left: 10vh;
  margin: auto;
  width: 100%;
  height: calc(100vh - 80px);
  left: 0;
  right: 0;

  :deep(.el-scrollbar) {
    .el-scrollbar__bar.is-horizontal>div {
      height: 10px;
    }

    .org-chart-container:nth-child(1) {
      & > .org-chart-node-children:nth-child(1) {
        & > .org-chart-node {
          margin-bottom: 50px;
          &::before,
          &::after {
            display: none;
          }
        }

      }
    }
  }
}
</style>
