<!--
 * @Descripttion: 弹出
 * @version: 1.0.0
 * @Author: xup@imyfone.cn
 * @Date: 2022-08-12 11:26:43
 * @LastEditors: heqi
 * @LastEditTime: 2022-09-07 17:07:57
-->
<template>
    <el-dialog v-model="dialogTableVisible" ref="dialogRef" :show-close="false"  width="955px" custom-class="target-dialog" destroy-on-close>
      <el-scrollbar max-height="600px">
        <OkrTemplate
          :showRightBtn="false"
          :isEvaluate="true"
          :okrData="okrTemplateItem"
          :isAlignTheViewBtn="false"
          :alignDep="department">
            <template #alignTheView>
              <div class="align-card" v-if="iconStatus && okrTemplateItem.align_middle.length > 0">
                <img src="@/assets/img/work/work-okr-align.svg">
                  <OkrAlignTarget :data="okrTemplateItem.align_middle"></OkrAlignTarget>

              </div>
            </template>
          </OkrTemplate>
      </el-scrollbar>
    </el-dialog>
</template>

<script>
import { getOkrInfo } from '@/apis/okr.js'
import OkrTemplate from '@/views/layout/work/okr/myokr/component/OkrTemplate'
import { reactive, toRefs } from '@vue/reactivity'
import OkrAlignTarget from '@/views/layout/work/okr/myokr/component/OkrAlignTarget'

export default {
  name: 'TargetOkrDialog',
  components: { OkrTemplate, OkrAlignTarget },
  props: {
    iconStatus: {
      type: Boolean,
      default: () => true
    }
  },
  setup (props) {
    const state = reactive({
      dialogTableVisible: false,
      okrTemplateItem: {},
      department: ''
    })

    // 开启弹出
    const dialog = async (item) => {
      state.department = item.dept_name
      const params = {
        cycle_id: '',
        dept_id: '',
        id: item.id
      }
      const { code, data } = await getOkrInfo(params)
      if (code === 0) {
        data.disabled = true
        state.okrTemplateItem = data
        state.dialogTableVisible = true
      }
      //   state.loading = false
      return false
    }

    return {
      ...toRefs(state),
      dialog
    }
  }
}
</script>

<style lang="less" scoped>
.target-dialog {
   .align-card {
      display: flex;
      align-items: baseline;
    }
  .target-wrapper {
    position: relative;
    left:0;
    top: 0;
    background: none;
    box-shadow: none;
    border: 0;
    margin-bottom: 20px;
    width: 100%;
    :deep {

      .triangle,
      .other-align {
         display: none;
      }

      .target-top,
      .target-content {
         border-bottom: none;
         padding-bottom: 0;
         padding-top: 0;
      }

      ul {
        margin-left: 20px;
        padding: 5px 0;
        border-radius: 8px;
        background: #f6f9fa;

        .target-title {
          padding-top: 5px;
          img {
            display: none;
          }
        }
      }
    }

  }
}
</style>
